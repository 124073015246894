<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="information" service="credit" firstStep backlink="/credit/registration" />
      <div class="content">
        <div class="content__container mobile_center">
          <p class="promo promo_blocks2">{{ "c_s2p5_text" | localize }}</p>
          <div class="service__container">
            <button class="service service service_members service_members_1" @click="saveAndGo(1)">
              <div class="service__title">{{ ("s2p5_block_1_title") | localize }}</div>
            </button>
            <button class="service service service_members service_members_2" @click="saveAndGo(2)">
              <div class="service__title">{{ ("s2p5_block_2_title") | localize }}</div>
            </button>
            <button class="service service_credit service_credit_1" @click="saveAndGo(3)">
              <div class="service__title">{{ "c_s2p3_text_1" | localize }}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import { commonMixin } from '@/mixins/commonMixin.js'
import { saveMixin } from '@/mixins/saveMixin.js'

export default {
  name: 'Credit_members',
  components: { Backlink },
  mixins: [commonMixin, saveMixin],
  methods: {
    saveAndGo(select) {
      const droppedSteps = [
        '/credit/price',
        '/credit/mortgage',
        '/credit/about-mortgage',
      ]

      // Сброс следующих шагов линии действий
      this.dropSteps( 'credit', this.$router.currentRoute.path, droppedSteps[0])

      if (select == 1) {
        this.addMissedSteps('credit', droppedSteps)
        this.saveServiceInfo('credit', { name: 'Credit clients', params: { clients: 1 } }, { credit_type: 0 })
      } else if (select == 2) {
        this.addMissedSteps('credit', droppedSteps)
        this.saveServiceInfo('credit', { name: 'Credit clients', params: { clients: 2 } }, { credit_type: 0 })
      } else {
        this.saveServiceInfo('credit', '/credit/price', { credit_type: 1 })
      }
    }
  }
}
</script>
